import React from 'react'
import {LayoutA} from '../../layouts'
import {DocToc} from '../../components/docs'
import CONFIG from '../../template-config'

class DocIndex extends React.Component {
  render() {
    return (
      <LayoutA>
        <div className="DocIndexPage DocTemplate">
          <div className="DocTemplate-toc">
            <DocToc/>
          </div>
          <div className="DocTemplate-content markdown-doc">
            <h1 className="title">{CONFIG.product} Overview</h1>
            <div>
              <p>Bacon ipsum dolor amet sausage biltong pork loin, swine beef ribs shankle rump shoulder landjaeger
                tongue pastrami. Burgdoggen ground round leberkas, venison beef ribs doner kevin pancetta shoulder
                tongue sirloin biltong. Jowl shoulder leberkas, kevin brisket ham picanha fatback swine doner
                andouille meatball corned beef chuck. Fatback prosciutto kevin shank cupim andouille ribeye beef
                venison pork chop burgdoggen strip steak sirloin chuck.
              </p>
              <div className="info">burgdoggen strip steak sirloin chuck.</div>
              <div className="warn">burgdoggen strip steak sirloin chuck.</div>
            </div>
          </div>
        </div>
      </LayoutA>
    )
  }

}

export default DocIndex
;
